<template>
<b-row>
    <b-col lg="12" sm="12">
        <body-card>
        <template>
            <div id="printMe">
                <!-- badc -->
                <div class="details_area">
                    <div class="header_part">
                        <div class="left_section">
                            <slot v-if="currentLocale === 'bn'">
                            বরাবর, <br>
                            সহকারী পরিচালক (ফার্টিলাইজার) <br>
                            {{$t('teaGarden.badc_sale_center')}} <br>
                            {{getSaleCenterName(app_details.proposed_badc_sale_center_id)}}, {{getSaleCenterAddress(app_details.proposed_badc_sale_center_id)}}।
                            </slot>
                            <slot v-else>
                            To, <br>
                            The Assistant Director (Fertilizer) <br>
                            {{$t('teaGarden.badc_sale_center')}} <br>
                            {{getSaleCenterName(app_details.proposed_badc_sale_center_id)}}, {{getSaleCenterAddress(app_details.proposed_badc_sale_center_id)}}
                            </slot>
                        </div>
                        <div class="right_section">
                        <img v-if="app_details.nominees[0] && app_details.nominees[0].photo" style="width: 100px;height: 100px;" :src="teaGardenServiceBaseUrl + 'storage' + app_details.nominees[0].photo" alt="nominee_image">
                        </div>
                    </div>
                    <div class="body-part" style="text-align: justify;">
                        <slot v-if="currentLocale === 'bn'">
                            <br>
                            <p>বিষয়: {{fiscalYearName(app_details.fiscal_year_id)}} অর্থবছরে চা বাগানে প্রয়োগের জন্য ভর্তুকি মূল্যে বারাদ্দকৃত সার উত্তোলন প্রসঙ্গে</p>
                            <br>
                            <p>প্রিয় মহোদয়,<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; উপর্যুক্ত বিষয়ে বাংলাদেশ চা বোর্ডের <span v-if="app_details.registration_no">পত্রের স্মারক নং- {{app_details.registration_no}}</span> <span v-if="app_details.approved_date">তারিখ: {{app_details.approved_date | dateFormat}}</span> বাংলাদেশ চা বোর্ড {{getGardenName(app_details.garden_id)}} চা বাগানের জন্য
                            <slot v-if="app_details.proposals">
                                <slot v-for="(item, index) in app_details.proposals">
                                    <span v-if="getFertilizerName(item.fertilizer_id) && getFertilizerName(item.fertilizer_id).toLowerCase() !== 'ইউরিয়া'" :key="index">
                                        {{$n(item.receipt_qty)}} মেট্রিক টন {{getFertilizerName(item.fertilizer_id)}}
                                        <span v-if="app_details.proposals.length -1 === index">সার</span>
                                        <span v-else>সার,</span>
                                    </span>
                                </slot>
                            </slot> বরাদ্দ করিয়াছেন ।
                            <br>
                            <slot v-if="app_details.proposals">
                                <slot v-for="(item, index) in app_details.proposals">
                                    <span v-if="getFertilizerName(item.fertilizer_id) && getFertilizerName(item.fertilizer_id).toLowerCase() !== 'ইউরিয়া'" :key="index">
                                        বারাদ্দকৃত {{$n(item.receipt_qty)}} মেট্রিক টন {{getFertilizerName(item.fertilizer_id)}}
                                        <span v-if="app_details.proposals.length -1 === index">সারের</span>
                                        <span v-else>সারের</span> মূল্য প্রতি মেট্রিক টন {{$n(item.unit_price)}}/- ({{convertNumberToBanglaWords(Number(item.unit_price))}}) টাকা হিসাবে ({{$n(item.receipt_qty)}} x {{$n(item.unit_price)}})={{$n(item.receipt_qty * item.unit_price)}}/- ({{convertNumberToBanglaWords(Number(item.receipt_qty * item.unit_price))}})  টাকা ডিডির মধ্যেমে প্রেরণ করা হল।
                                        <span v-if="getNomineePayOrderBankName(item.fertilizer_id)">ব্যাংক: {{getNomineePayOrderBankName(item.fertilizer_id)}},</span>
                                        <span v-if="getNomineePayOrderBranchName(item.fertilizer_id)"> শাখা: {{getNomineePayOrderBranchName(item.fertilizer_id)}},</span>
                                        <span v-if="getNomineePayOrderNo(item.fertilizer_id)"> ডিডি নং: {{getNomineePayOrderNo(item.fertilizer_id) }},</span>
                                        <span v-if="getNomineePayOrderDate(item.fertilizer_id)"> তারিখ: {{ getNomineePayOrderDate(item.fertilizer_id) | dateFormat}}</span> <br><br>
                                    </span>
                                </slot>
                            </slot>
                            </p>
                            <p>জনাব <span v-if="app_details.nominees[0] && app_details.nominees[0].name_bn">{{app_details.nominees[0].name_bn}}</span>, <span v-if="app_details.nominees[0] && app_details.nominees[0].designation_bn">{{app_details.nominees[0].designation_bn}}</span>, {{getGardenName(app_details.garden_id)}}। তিনি বাগানের পক্ষে সার উত্তোলন করিবেন। তাহার ফটো ও নমুনা স্বাক্ষর সম্বলিত পত্র প্রেরণ করা হল। তাহার নিকট বারাদ্দকৃত
                            <slot v-if="app_details.proposals">
                                <slot v-for="(item, index) in app_details.proposals">
                                    <span v-if="getFertilizerName(item.fertilizer_id) && getFertilizerName(item.fertilizer_id).toLowerCase() !== 'ইউরিয়া'" :key="index">
                                        {{$n(item.receipt_qty)}} মেট্রিক টন {{getFertilizerName(item.fertilizer_id)}}
                                        <span v-if="app_details.proposals.length -1 === index">সার</span>
                                        <span v-else>সার,</span>
                                    </span>
                                </slot>
                            </slot> সরবরাহ করার জন্যে অনুরোধ করা হল।</p>
                            <p>জনাব <span v-if="app_details.nominees[0] && app_details.nominees[0].name_bn">{{app_details.nominees[0].name_bn}}</span> এর নমুনা স্বাক্ষরঃ</p>
                            <p><img style="width: 90px;height: 60px;" :src="teaGardenServiceBaseUrl + 'storage' + app_details.nominees[0].signature" alt="nominee_signature"></p>
                            <div class="d-flex justify-content-end">
                                <div v-if="document_signatory" style="text-align:center">
                                    আপনার বিশ্বস্ত, <br>
                                <img style="width:60px" v-if="document_signatory.show_signature" :src="authServiceBaseUrl + 'storage' + document_signatory.signature" alt=""> <br>
                                <span v-if="document_signatory.show_name">{{document_signatory.name_bn}}</span> <br>
                                <span v-if="document_signatory.show_name">({{getDesignationName(document_signatory.designation_id)}})</span>
                                </div>
                            </div>
                        </slot>
                        <slot v-else>
                            <br>
                            <p>Subject: Regarding extraction of fertilizers allocated at subsidized cost for application in tea plantations during the financial year {{fiscalYearName(app_details.fiscal_year_id)}}.</p>
                            <br>
                            <p>Dear sir,<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bangladesh Tea Board's letter <span>memorandum No-{{app_details.registration_no}}</span> dated the above matter; {{app_details.approved_date | dateFormat}} Bangladesh Tea Board has allocated
                            <slot v-if="app_details.proposals">
                                <slot v-for="(item, index) in app_details.proposals">
                                    <span v-if="getFertilizerName(item.fertilizer_id) && getFertilizerName(item.fertilizer_id).toLowerCase() !== 'urea'" :key="index">
                                        {{$n(item.receipt_qty)}} metric tons of {{getFertilizerName(item.fertilizer_id)}}
                                        <span v-if="app_details.proposals.length -1 === index">fertilizer</span>
                                        <span v-else>fertilizer,</span>
                                    </span>
                                </slot>
                            </slot>
                            for {{getGardenName(app_details.garden_id)}}. <br>
                            <slot v-if="app_details.proposals">
                                <slot v-for="(item, index) in app_details.proposals">
                                    <span v-if="getFertilizerName(item.fertilizer_id) && getFertilizerName(item.fertilizer_id).toLowerCase() !== 'urea'" :key="index">
                                        Allocated {{$n(item.receipt_qty)}} metric tons of {{getFertilizerName(item.fertilizer_id)}}
                                        <span v-if="app_details.proposals.length -1 === index">Fertilizers</span>
                                        <span v-else>Fertilizers</span> worth Tk {{$n(item.unit_price)}}/- ({{convertNumberToEnglishWords(Number(item.unit_price))}}) per metric as ({{$n(item.receipt_qty)}} x {{$n(item.unit_price)}})= Tk {{$n(item.receipt_qty * item.unit_price)}}/- ({{convertNumberToEnglishWords(Number(item.receipt_qty * item.unit_price))}})  remitted through DD Hall
                                        <span v-if="getNomineePayOrderBankName(item.fertilizer_id)">Bank: {{getNomineePayOrderBankName(item.fertilizer_id)}},</span>
                                        <span v-if="getNomineePayOrderBranchName(item.fertilizer_id)"> Branch: {{getNomineePayOrderBranchName(item.fertilizer_id)}},</span>
                                        <span v-if="getNomineePayOrderNo(item.fertilizer_id)"> DD No: {{getNomineePayOrderNo(item.fertilizer_id) }},</span>
                                        <span v-if="getNomineePayOrderDate(item.fertilizer_id)"> Date: {{ getNomineePayOrderDate(item.fertilizer_id) | dateFormat}}</span> <br>
                                    </span>
                                </slot>
                            </slot>
                            </p>
                            <p>
                            Mr. <span v-if="app_details.nominees[0] && app_details.nominees[0].name_en">{{app_details.nominees[0].name_en}}</span>, <span v-if="app_details.nominees[0] && app_details.nominees[0].designation_en">{{app_details.nominees[0].designation_en}}</span>, {{getGardenName(app_details.garden_id)}} Tea Plantation. He will lift the manure for the garden. His photo and sample signature has been sent to the letter. He was requested to supply the allotted
                                <slot v-for="(item, index) in app_details.proposals">
                                    <span v-if="getFertilizerName(item.fertilizer_id) && getFertilizerName(item.fertilizer_id).toLowerCase() !== 'urea'" :key="index">
                                        {{$n(item.receipt_qty)}} metric tons of {{getFertilizerName(item.fertilizer_id)}}
                                        <span v-if="app_details.proposals.length -1 === index">fertilizer</span>
                                        <span v-else>fertilizer,</span>
                                    </span>
                                </slot>
                            </p>
                            <p>Sample handwriting of Mr.  <span v-if="app_details.nominees[0] && app_details.nominees[0].name_en">{{app_details.nominees[0].name_en}}</span>:</p>
                            <p><img style="width: 90px;height: 60px;" :src="teaGardenServiceBaseUrl + 'storage' + app_details.nominees[0].signature" alt="nominee_signature"></p>
                            <div class="d-flex justify-content-end">
                                <div v-if="document_signatory" style="text-align:center">
                                    Your faithful, <br>
                                <img style="width:60px" v-if="document_signatory.show_signature" :src="authServiceBaseUrl + 'storage' + document_signatory.signature" alt=""> <br>
                                <span v-if="document_signatory.show_name">{{document_signatory.name_en}}</span> <br>
                                <span v-if="document_signatory.show_name">({{getDesignationName(document_signatory.designation_id)}})</span>
                                </div>
                            </div>
                        </slot>
                    </div>
                </div>
                <!-- bcic -->
                <br><br><br><br><br>
                <div class="details_area next_page" style='page-break-before: always;'>
                    <div class="header_part">
                        <div class="left_section">
                            <slot v-if="currentLocale === 'bn'">
                            বরাবর, <br>
                            সহকারী পরিচালক (ফার্টিলাইজার) <br>
                            {{$t('teaGarden.bcic_sale_center')}} <br>
                            {{getSaleCenterName(app_details.proposed_bcic_sale_center_id)}}, {{getSaleCenterAddress(app_details.proposed_bcic_sale_center_id)}}।
                            </slot>
                            <slot v-else>
                            To, <br>
                            The Assistant Director (Fertilizer) <br>
                            {{$t('teaGarden.bcic_sale_center')}} <br>
                            {{getSaleCenterName(app_details.proposed_bcic_sale_center_id)}}, {{getSaleCenterAddress(app_details.proposed_bcic_sale_center_id)}}
                            </slot>
                        </div>
                        <div class="right_section">
                        <img v-if="app_details.nominees[1] && app_details.nominees[1].photo" style="width: 100px;height: 100px;" :src="teaGardenServiceBaseUrl + 'storage' + app_details.nominees[1].photo" alt="nominee_image">
                        </div>
                    </div>
                    <div class="body-part" style="text-align: justify;">
                        <slot v-if="currentLocale === 'bn'">
                            <br>
                            <p>বিষয়: {{fiscalYearName(app_details.fiscal_year_id)}} অর্থবছরে চা বাগানে প্রয়োগের জন্য ভর্তুকি মূল্যে বারাদ্দকৃত সার উত্তোলন প্রসঙ্গে</p>
                            <br>
                            <p>প্রিয় মহোদয়,<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;উপর্যুক্ত বিষয়ে বাংলাদেশ চা বোর্ডের <span v-if="app_details.registration_no">পত্রের স্মারক নং- {{app_details.registration_no}}</span> <span v-if="app_details.approved_date">তারিখ: {{app_details.approved_date | dateFormat}}</span> বাংলাদেশ চা বোর্ড {{getGardenName(app_details.garden_id)}} চা বাগানের জন্য
                            <slot v-if="app_details.proposals">
                                <slot v-for="(item, index) in app_details.proposals">
                                    <span v-if="getFertilizerName(item.fertilizer_id) && getFertilizerName(item.fertilizer_id).toLowerCase() === 'ইউরিয়া'" :key="index">
                                        {{$n(item.receipt_qty)}} মেট্রিক টন {{getFertilizerName(item.fertilizer_id)}}
                                        <span v-if="app_details.proposals.length -1 === index">সার</span>
                                        <span v-else>সার,</span>
                                    </span>
                                </slot>
                            </slot> বরাদ্দ করিয়াছেন ।
                               <br><br>
                                <slot v-if="app_details.proposals">
                                <slot v-for="(item, index) in app_details.proposals">
                                    <span v-if="getFertilizerName(item.fertilizer_id) && getFertilizerName(item.fertilizer_id).toLowerCase() === 'ইউরিয়া'" :key="index">
                                        বারাদ্দকৃত {{$n(item.receipt_qty)}} মেট্রিক টন {{getFertilizerName(item.fertilizer_id)}}
                                        <span v-if="app_details.proposals.length -1 === index">সারের</span>
                                        <span v-else>সারের</span> মূল্য প্রতি মেট্রিক টন {{$n(item.unit_price)}}/- ({{convertNumberToBanglaWords(Number(item.unit_price))}}) টাকা হিসাবে ({{$n(item.receipt_qty)}} x {{$n(item.unit_price)}})={{$n(item.receipt_qty * item.unit_price)}}/- ({{convertNumberToBanglaWords(Number(item.receipt_qty * item.unit_price))}})  টাকা ডিডির মধ্যেমে প্রেরণ করা হল।
                                        <span v-if="getNomineePayOrderBankName(item.fertilizer_id)">ব্যাংক: {{getNomineePayOrderBankName(item.fertilizer_id)}},</span>
                                        <span v-if="getNomineePayOrderBranchName(item.fertilizer_id)"> শাখা: {{getNomineePayOrderBranchName(item.fertilizer_id)}},</span>
                                        <span v-if="getNomineePayOrderNo(item.fertilizer_id)"> ডিডি নং: {{getNomineePayOrderNo(item.fertilizer_id) }},</span>
                                        <span v-if="getNomineePayOrderDate(item.fertilizer_id)"> তারিখ: {{ getNomineePayOrderDate(item.fertilizer_id) | dateFormat}}</span>
                                    </span>
                                </slot>
                            </slot>
                            </p>
                        <p>জনাব <span v-if="app_details.nominees[1] && app_details.nominees[1].name_bn">{{app_details.nominees[1].name_bn}}</span>, <span v-if="app_details.nominees[1] && app_details.nominees[1].designation_bn">{{app_details.nominees[1].designation_bn}}</span>, {{getGardenName(app_details.garden_id)}}। তিনি বাগানের পক্ষে সার উত্তোলন করিবেন। তাহার ফটো ও নমুনা স্বাক্ষর সম্বলিত পত্র প্রেরণ করা হল। তাহার নিকট বারাদ্দকৃত
                            <slot v-if="app_details.proposals">
                                <slot v-for="(item, index) in app_details.proposals">
                                    <span v-if="getFertilizerName(item.fertilizer_id) && getFertilizerName(item.fertilizer_id).toLowerCase() === 'ইউরিয়া'" :key="index">
                                        {{$n(item.receipt_qty)}} মেট্রিক টন {{getFertilizerName(item.fertilizer_id)}} সার
                                    </span>
                                </slot>
                            </slot> সরবরাহ করার জন্যে অনুরোধ করা হল।</p>
                            <br>
                            <p>জনাব <span v-if="app_details.nominees[1] && app_details.nominees[1].name_bn">{{app_details.nominees[1].name_bn}}</span> এর নমুনা স্বাক্ষরঃ</p>
                            <p><img v-if="app_details.nominees[1] && app_details.nominees[1].signature" style="width: 90px;height: 60px;" :src="teaGardenServiceBaseUrl + 'storage' + app_details.nominees[1].signature" alt="nominee_signature"></p>
                            <div class="d-flex justify-content-end">
                                <div v-if="document_signatory" style="text-align:center">
                                    আপনার বিশ্বস্ত <br>
                                <img style="width:60px" v-if="document_signatory.show_signature" :src="authServiceBaseUrl + 'storage' + document_signatory.signature" alt=""> <br>
                                <span v-if="document_signatory.show_name">{{document_signatory.name_bn}}</span> <br>
                                <span v-if="document_signatory.show_name">({{getDesignationName(document_signatory.designation_id)}})</span>
                                </div>
                            </div>
                        </slot>
                        <slot v-else>
                            <br>
                            <p>Subject: Regarding extraction of fertilizers allocated at subsidized cost for application in tea plantations during the financial year {{fiscalYearName(app_details.fiscal_year_id)}}.</p>
                            <br>
                            <p>Dear sir,<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bangladesh Tea Board's letter  <span>memorandum No-{{app_details.registration_no}}</span> dated the above matter; {{app_details.approved_date | dateFormat}} Bangladesh Tea Board has allocated
                            <slot v-if="app_details.proposals">
                                <slot v-for="(item, index) in app_details.proposals">
                                    <span v-if="getFertilizerName(item.fertilizer_id) && getFertilizerName(item.fertilizer_id).toLowerCase() === 'urea'" :key="index">
                                        {{$n(item.receipt_qty)}} metric tons of {{getFertilizerName(item.fertilizer_id)}}
                                        <span v-if="app_details.proposals.length -1 === index">fertilizer</span>
                                        <span v-else>fertilizer,</span>
                                    </span>
                                </slot>
                            </slot>
                            for {{getGardenName(app_details.garden_id)}}. <br><br>
                            <slot v-if="app_details.proposals">
                                <slot v-for="(item, index) in app_details.proposals">
                                    <span v-if="getFertilizerName(item.fertilizer_id) && getFertilizerName(item.fertilizer_id).toLowerCase() === 'urea'" :key="index">
                                        Allocated {{$n(item.receipt_qty)}} metric tons of {{getFertilizerName(item.fertilizer_id)}}
                                        <span v-if="app_details.proposals.length -1 === index">Fertilizers</span>
                                        <span v-else>Fertilizers</span> worth Tk {{$n(item.unit_price)}}/- ({{convertNumberToEnglishWords(Number(item.unit_price))}}) per metric as ({{$n(item.receipt_qty)}} x {{$n(item.unit_price)}})= Tk {{$n(item.receipt_qty * item.unit_price)}}/- ({{convertNumberToEnglishWords(Number(item.receipt_qty * item.unit_price))}})  remitted through DD Hall
                                        <span v-if="getNomineePayOrderBankName(item.fertilizer_id)">Bank: {{getNomineePayOrderBankName(item.fertilizer_id)}},</span>
                                        <span v-if="getNomineePayOrderBranchName(item.fertilizer_id)"> Branch: {{getNomineePayOrderBranchName(item.fertilizer_id)}},</span>
                                        <span v-if="getNomineePayOrderNo(item.fertilizer_id)"> DD No: {{getNomineePayOrderNo(item.fertilizer_id) }},</span>
                                        <span v-if="getNomineePayOrderDate(item.fertilizer_id)"> Date: {{ getNomineePayOrderDate(item.fertilizer_id) | dateFormat}}</span>
                                    </span>
                                </slot>
                            </slot>
                            </p>
                            <p>
                            Mr. <span v-if="app_details.nominees[1] && app_details.nominees[1].name_en">{{app_details.nominees[1].name_en}}</span>, <span v-if="app_details.nominees[1] && app_details.nominees[1].designation_en">{{app_details.nominees[1].designation_en}}</span>, {{getGardenName(app_details.garden_id)}} Tea Plantation. He will lift the manure for the garden. His photo and sample signature has been sent to the letter. He was requested to supply the allotted
                                <slot v-for="(item, index) in app_details.proposals">
                                    <span v-if="getFertilizerName(item.fertilizer_id) && getFertilizerName(item.fertilizer_id).toLowerCase() === 'urea'" :key="index">
                                        {{$n(item.receipt_qty)}} metric tons of {{getFertilizerName(item.fertilizer_id)}}
                                        fertilizers
                                    </span>
                                </slot>
                            </p>
                            <br>
                            <p>Sample handwriting of Mr.  <span v-if="app_details.nominees[1] && app_details.nominees[1].name_en">{{app_details.nominees[1].name_en}}</span>:</p>
                            <p><img v-if="app_details.nominees[1] && app_details.nominees[1].signature" style="width: 90px;height: 60px;" :src="teaGardenServiceBaseUrl + 'storage' + app_details.nominees[1].signature" alt="nominee_signature"></p>
                            <div class="d-flex justify-content-end">
                                <div v-if="document_signatory" style="text-align:center">
                                    Your faithful, <br>
                                <img style="width:60px" v-if="document_signatory.show_signature" :src="authServiceBaseUrl + 'storage' + document_signatory.signature" alt=""> <br>
                                <span v-if="document_signatory.show_name">{{document_signatory.name_en}}</span> <br>
                                <span v-if="document_signatory.show_name">({{getDesignationName(document_signatory.designation_id)}})</span>
                                </div>
                            </div>
                        </slot>
                    </div>
                </div>
            </div>
        </template>
        </body-card>
        <b-row class="text-right">
            <b-col>
                <b-button variant="success" @click="print" class="mr-2 btn-sm">{{ $t('globalTrans.download') }}</b-button>
                <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('nominee-approved-copy')">{{ $t('globalTrans.cancel') }}</b-button>
            </b-col>
        </b-row>
    </b-col>
</b-row>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { fertilizerRequestView } from '../../../api/routes'
import Vue from 'vue'
import VueHtmlToPaper from 'vue-html-to-paper'
Vue.use(VueHtmlToPaper)
export default {
  name: 'NomineeApprovedCopy',
  props: ['app_details'],
  components: {
  },
  data () {
    return {
        teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        authServiceBaseUrl: authServiceBaseUrl,
        document_signatory: {},
        pageStyle: {
            styles: [
                'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
                `${teaGardenServiceBaseUrl}/fertilizer_receipt.css`
            ]
        },
        nominee_all_dd_infos: {}
    }
  },
  created () {
    this.document_signatory = this.$store.state.TeaGardenService.commonObj.masterDocumentSignatoryList.find(item => item.service_id === 19)
    const allDDNominee1 = this.app_details.nominees[0].dd_details
    const allDDNominee2 = this.app_details.nominees[1].dd_details
    this.nominee_all_dd_infos = [...allDDNominee1, ...allDDNominee2]
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    loading () {
      return this.$store.state.commonObj.loading
    }
  },
  methods: {
    print () {
        this.$htmlToPaper('printMe', this.pageStyle)
    },
    async pdfExport () {
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5, app_id: this.appId })
      const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 19)
      if (service !== undefined) {
        if (service.office_type_id) {
          params.office_type_id = service.office_type_id
        }
        if (service.office_id) {
          params.office_id = service.office_id
        }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, fertilizerRequestView + '/' + this.appId, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getFertilizerName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterFertilizerList.find(item => item.value === id)
      if (obj) {
        return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getSaleCenterName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterFertilizerDistributionList.find(item => item.value === id)
      if (obj) {
        return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getSaleCenterAddress (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterFertilizerDistributionList.find(item => item.value === id)
      if (obj) {
        return this.currentLocale === 'en' ? obj.distrib_center_address_en : obj.distrib_center_address_bn
      }
    },
    getGardenName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === id)
      if (obj) {
        return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getDesignationName (id) {
      const obj = this.$store.state.CommonService.commonObj.designationList.find(item => item.value === id)
      if (obj) {
        return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    fiscalYearName (id) {
      const obj = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === id)
      if (obj) {
        return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    // convert to word start
    empty (str) {
        return (!str || str.length === 0)
    },
    toWord (number, words) {
        var nLength = number.length
        var wordsString = ''

        if (nLength <= 9) {
            const nArray = [0, 0, 0, 0, 0, 0, 0, 0, 0]
            const receivedNArray = []
            for (let i = 0; i < nLength; i++) {
            receivedNArray[i] = parseInt(number.substr(i, 1))
            }
            for (let i = 9 - nLength, j = 0; i < 9; i++, j++) {
            nArray[i] = receivedNArray[j]
            }
            for (let i = 0, j = 1; i < 9; i++, j++) {
            if (i === 0 || i === 2 || i === 4 || i === 7) {
                if (nArray[i] === 1) {
                nArray[j] = 10 + parseInt(nArray[j])
                nArray[i] = 0
                } else if (nArray[i] === 2) {
                nArray[j] = 20 + parseInt(nArray[j])
                nArray[i] = 0
                } else if (nArray[i] === 3) {
                nArray[j] = 30 + parseInt(nArray[j])
                nArray[i] = 0
                } else if (nArray[i] === 4) {
                nArray[j] = 40 + parseInt(nArray[j])
                nArray[i] = 0
                } else if (nArray[i] === 5) {
                nArray[j] = 50 + parseInt(nArray[j])
                nArray[i] = 0
                } else if (nArray[i] === 6) {
                nArray[j] = 60 + parseInt(nArray[j])
                nArray[i] = 0
                } else if (nArray[i] === 7) {
                nArray[j] = 70 + parseInt(nArray[j])
                nArray[i] = 0
                } else if (nArray[i] === 8) {
                nArray[j] = 80 + parseInt(nArray[j])
                nArray[i] = 0
                } else if (nArray[i] === 9) {
                nArray[j] = 90 + parseInt(nArray[j])
                nArray[i] = 0
                }
            }
            }

            let value = ''
            for (let i = 0; i < 9; i++) {
            value = parseInt(nArray[i])
            if (value !== 0) {
                wordsString += words[value] + ''
            }
            if ((i === 1 && value !== 0) || (i === 0 && value !== 0 && parseInt(nArray[i + 1]) === 0)) {
                wordsString += ' কোটি '
            }
            if ((i === 3 && value !== 0) || (i === 2 && value !== 0 && parseInt(nArray[i + 1]) === 0)) {
                wordsString += ' লাখ '
            }
            if ((i === 5 && value !== 0) || (i === 4 && value !== 0 && parseInt(nArray[i + 1]) === 0)) {
                wordsString += ' হাজার '
            } else if (i === 6 && value !== 0) {
                wordsString += 'শ '
            }
            }

            wordsString = wordsString.split('  ').join(' ')
        }
        return wordsString
    },
    convertNumberToEnglishWords (number) {
        if ((number < 0) || (number > 999999999)) {
            alert('Number is out of range')
            return
        }
        const numberArray = number.toString().split('.')
        const numberWithoutFloatingPart = numberArray[0] ? parseInt(numberArray[0]) : 0
        const numberWithFloatingPart = numberArray[1]
        let stringBeforeFloatingPart = ''
        if (numberWithoutFloatingPart) {
            stringBeforeFloatingPart = this.convertNumber(numberWithoutFloatingPart)
        }
        let numberWords = stringBeforeFloatingPart
        if (numberWithFloatingPart) {
            if (stringBeforeFloatingPart) {
            numberWords += stringBeforeFloatingPart + ' Point ' + this.convertNumber(parseInt(numberWithFloatingPart))
            } else {
            numberWords += 'Point ' + this.convertNumber(parseInt(numberWithFloatingPart))
            }
        }
        return numberWords
    },
    convertNumber (number) {
        const Kt = Math.floor(number / 10000000) /* Koti */
        number -= Kt * 10000000
        const Gn = Math.floor(number / 100000) /* lakh  */
        number -= Gn * 100000
        const kn = Math.floor(number / 1000) /* Thousands (kilo) */
        number -= kn * 1000
        const Hn = Math.floor(number / 100) /* Hundreds (hecto) */
        number -= Hn * 100
        const Dn = Math.floor(number / 10) /* Tens (deca) */
        const n = number % 10 /* Ones */

        let res = ''

        if (Kt) {
            res += this.convertNumber(Kt) + ' Koti '
        }
        if (Gn) {
            res += this.convertNumber(Gn) + ' Lakh'
        }

        if (kn) {
            res += (this.empty(res) ? '' : ' ') +
            this.convertNumber(kn) + ' Thousand'
        }

        if (Hn) {
            res += (this.empty(res) ? '' : ' ') +
            this.convertNumber(Hn) + ' Hundred'
        }

        const ones = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six',
            'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve', 'Thirteen',
            'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eightteen',
            'Nineteen']
        const tens = ['', '', 'Twenty', 'Thirty', 'Fourty', 'Fifty', 'Sixty',
            'Seventy', 'Eigthy', 'Ninety']

        if (Dn || n) {
            if (!this.empty(res)) {
            res += ' and '
            }

            if (Dn < 2) {
            res += ones[Dn * 10 + n]
            } else {
            res += tens[Dn]

            if (n) {
                res += '-' + ones[n]
            }
            }
        }

        if (this.empty(res)) {
            res = 'zero'
        }

        return res
    },
    convertNumberToBanglaWords (amount) {
        const Words = ['', 'এক', 'দুই', 'তিন', 'চার', 'পাঁচ', 'ছয়', 'সাত', 'আট', 'নয়', 'দশ',
            'এগার', 'বার', 'তের', 'চৌদ্দ', 'পনের', 'ষোল', 'সতের', 'আঠার', 'ঊনিশ', 'বিশ',
            'একুশ', 'বাইশ', 'তেইশ', 'চব্বিশ', 'পঁচিশ', 'ছাব্বিশ', 'সাতাশ', 'আঠাশ', 'ঊনত্রিশ', 'ত্রিশ',
            'একত্রিশ', 'বত্রিশ', 'তেত্রিশ', 'চৌত্রিশ', 'পঁয়ত্রিশ', 'ছত্রিশ', 'সাঁইত্রিশ', 'আটত্রিশ', 'ঊনচল্লিশ', 'চল্লিশ',
            'একচল্লিশ', 'বিয়াল্লিশ', 'তেতাল্লিশ', 'চুয়াল্লিশ', 'পঁয়তাল্লিশ', 'ছেচল্লিশ', 'সাতচল্লিশ', 'আটচল্লিশ', 'ঊনপঞ্চাশ', 'পঞ্চাশ',
            'একান্ন', 'বায়ান্ন', 'তিপ্পান্ন', 'চুয়ান্ন', 'পঞ্চান্ন', 'ছাপ্পান্ন', 'সাতান্ন', 'আটান্ন', 'ঊনষাট', 'ষাট',
            'একষট্টি', 'বাষট্টি', 'তেষট্টি', 'চৌষট্টি', 'পঁয়ষট্টি', 'ছেষট্টি', 'সাতষট্টি', 'আটষট্টি', 'ঊনসত্তর', 'সত্তর',
            'একাতর', 'বাহাত্তর', 'তিয়াত্তর', 'চুয়াত্তর', 'পঁচাত্তর', 'ছিয়াত্তর', 'সাতাত্তর', 'আটাত্তর', 'ঊনআশি', 'আশি',
            'একাশি', 'বিরাশি', 'তিরাশি', 'চুরাশি', 'পঁচাশি', 'ছিয়াশি', 'সাতাশি', 'আটাশি', 'ঊননব্বই', 'নব্বই',
            'একানব্বই', 'বিরানব্বই', 'তিরানব্বই', 'চুরানব্বই', 'পঁচানব্বই', 'ছিয়ানব্বই', 'সাতানব্বই', 'আটানব্বই', 'নিরানব্বই']

        amount = amount.toString()
        const atemp = amount.split('.')
        let beforeWord = ''
        let afterWord = ''
        const beforeNumber = atemp[0]
        if (beforeNumber !== '0') {
            beforeWord = this.toWord(beforeNumber, Words)
        }
        if (atemp.length === 2) {
            const afterNumber = atemp[1]
            afterWord = this.toWord(afterNumber, Words)
            if (beforeWord !== '') {
            beforeWord += ' দশমিক ' + afterWord
            } else {
            beforeWord += 'দশমিক ' + afterWord
            }
        }
        return beforeWord
    },
    // convert to word end,
    getNomineePayOrderNo (fertilizerId) {
        const obj = this.nominee_all_dd_infos.find(item => item.fertilizer_id === fertilizerId)
        if (obj) {
            return obj.pay_order_no
        } else {
            return ''
        }
    },
    getNomineePayOrderDate (fertilizerId) {
        const obj = this.nominee_all_dd_infos.find(item => item.fertilizer_id === fertilizerId)
        if (obj) {
            return obj.pay_order_date
        } else {
            return ''
        }
    },
     getBankName (bankId) {
        const obj = this.$store.state.CommonService.commonObj.bankList.find(item => item.value === bankId)
        if (obj) {
            return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
        } else {
            return ''
        }
    },
    getNomineePayOrderBankName (fertilizerId) {
        const obj = this.nominee_all_dd_infos.find(item => item.fertilizer_id === fertilizerId)
        if (obj) {
            if (obj.bank_id) {
                return this.getBankName(parseInt(obj.bank_id))
            }
        } else {
            return ''
        }
    },
    getBranchName (branchId) {
        const obj = this.$store.state.CommonService.commonObj.branchList.find(item => item.value === branchId)
        if (obj) {
            return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
        } else {
            return ''
        }
    },
    getNomineePayOrderBranchName (fertilizerId) {
        const obj = this.nominee_all_dd_infos.find(item => item.fertilizer_id === fertilizerId)
        if (obj) {
            if (obj.branch_id) {
               return this.getBranchName(parseInt(obj.branch_id))
            }
        } else {
            return ''
        }
    }
  }
}
</script>
<style scoped>
  .details_area{
    padding: 30px 30px;
  }
  .header_part {
    display: flex;
  }
 .left_section{
    width: 600px;
  }
 .right_section{
    text-align: right;
  }

</style>
